.header-section {
    .toolbar {
        padding-top: 72px;
    }
    .menuButton {
        @media only screen and (min-width: 601px) {
            display: none;
        }
    }
}

.Mui-selected {
    .MuiSvgIcon-root {
        color: #0085ad !important;
    }
    .MuiListItemText-root {
        color: #0085ad !important;
    }
}

.picker-text-class {
    &.MuiFormControl-marginNormal {
        margin-top: 8px ;
    }
    .MuiInputBase-input {
        padding: 12px 12px 8px 10px;
        
    }
}

.falcon-button-block {
    .w3-col {
        padding: 8px;
    }
}

#root {
    min-height: 100vh;
}

.falcon-margin-auto {
    margin: auto;
}

.falcon-margin {
    margin: auto;
    margin-top: 50px;
}

html {
    background: url("https://cloud.xylem.com/xcloud/usermanagementweb/img/login_bg_web_2.jpg");
    background-size: cover;
}

.error-text {
    font-size: 14px;
    color: red;
}

.textMarginCenter {
    text-align: center;
    padding-top: 32px;
}

.notFoundMsg {
    font-size: 20px;
}

.marginAuto {
    margin: auto !important;
}

.marginTop50 {
    margin-top: 50px !important;
}

.zero-padding {
    padding: 0 !important;
}

.rightAlign {
    text-align: right;
}

.alignCenter {
    text-align: center;
}

.padding16 {
    padding: 16px !important;
}

.createBtn {
    position: absolute !important;
    right: 110px;
    top: 15px;
    z-index: 1202;
}

.responsive-table {
    width: 100%;
    overflow-x: auto;
}

.notification-card:hover {
    background: url("https://www.xylect.com/html/PICTURE/kachel3-hover.png");
}

.app-auth-form {
    .MuiCard-root {
        background: #e9ebed;
        input {
            background: white;
        }
    }
}

img {
    page-break-before: avoid;
    /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-after: avoid;
    /* 'always,' 'avoid,' 'left,' 'inherit,' or 'right' */
    page-break-inside: avoid;
    /* or 'auto' */
}

.notification-card {
    background: url("https://www.xylect.com/html/PICTURE/kachel3.png");
    color: white;
    border-radius: 0px;
    .stats-content {
        line-height: 140px;
        font-size: 60px;
        text-align: center;
    }
    h6 {
        font-weight: bold;
    }
    .notification-head {
        text-align: center;
        border-bottom: 1px solid #cccccc;
        padding: 1px;
    }
}

.card-padding {
    padding: 15px 30px;
}

.app-footer {
    width: 100%;
    background: rgb(77, 160, 180);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 40px;
    position: fixed;
    bottom: 0px;
    min-height: 40px;
    z-index: 1210;
    img {
        vertical-align: middle;
        position: absolute;
        right: 0px;
        height: 80px;
        bottom: -1px;
    }
    .copy-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10px;
        color: #ffffff;
    }
}

.appBar {
    background-color: #0085ad !important;
    box-shadow: none !important;
}

.vertical-align {
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-item-card {
    background: #ffffff;
    border: dashed 1px #333;
    cursor: pointer;
    min-height: 120px;
    line-height: 120px;
}

.stats-cards {
    .stats-card-item {
        padding: 10px;
        margin: 16px;
    }
}

@media only screen and (max-width: 601px) {
    .createBtn {
        position: relative !important;
        right: 20px;
        top: 0px;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.description-text {
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0px;
}

.wrap-text {
    white-space: pre-wrap;
}

.no-wrap-text {
    white-space: nowrap;
}

.non-clickable {
    cursor: not-allowed;
}

.pagination {
    margin: auto;
    .current-page {
        color: white;
        background-color: #0085ad;
    }
    .page-no {
        color: black;
        background-color: white;
    }
}

.team-list {
    .no-team-row {
        td {
            border-bottom: 0;
        }
    }
}

.falcon-doughnut-center-text {
    font-weight: 600;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.87);
}
.main-content {
    margin-top: 86px;
    border-bottom: solid 100px #f1f1f1;
    background: #f1f1f1;
    min-height: 100vh;
}

.app-auth-form {
    .MuiCard-root {
        max-width: 360px;
        min-width: 240px;
        margin: auto;
    }
}

.padding-bottom-0 {
    padding-bottom: 0px !important;
}
.user-card.MuiCard-root {
    border-radius: 8px;
    box-shadow: none;
    border:solid 1px #EEE;
}
.action-table {
    .MuiTableRow-root {
        .MuiButtonBase-root {
            .MuiIconButton-label {
                color:#BBB;
            }
            // visibility: hidden;
        }
        &:hover {
            .MuiButtonBase-root { 
                .MuiIconButton-label {
                     color : rgb(23, 129, 194);
                }
            }
        }
    }
}

.secondary-menu {
    position: fixed;
    top: 48px;
    background-color: rgb(244, 248, 255);
    z-index: 100;
    width: 100%;
    margin-bottom: 60px;
    box-shadow: gray 0px 0px 4px;
    a.main-menu {
        border-radius: 0px;
        border-bottom: solid 2px rgb(244, 248, 255);
        min-width: 120px;
    }
    a.main-menu.selected {
        border-width: 4px;
        border-bottom: solid 2px rgb(60, 105, 201);
    }
    .MuiButton-label:hover {
        color: rgb(18, 138, 230);
    }
    .MuiButton-label {
        text-transform: none;
        color: rgb(9, 80, 135);
    }
}
.table-borders {
    th,
    td {
        border: 1px solid black;
    }
}

.button {
    text-transform: none;
    background-color: #0085ad !important;
    color: #fff !important;
    &:hover {
        background-color: #0085ad !important;
    }
}

.settings {
    background-color: white;
    justify-content: center;
}
